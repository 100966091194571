/** @jsx jsx */
import { jsx } from "theme-ui"
import { Box, Heading } from "@theme-ui/components"
import { getArticleUrl } from '../../utils'
import styled from '@emotion/styled'
import { Link } from "gatsby"

const ArticlesList = ({ topic, articles, supressTitle = false, style = {} }) => {
  return (
    <Box sx={{ mx: 2, ...style }} >
      {!supressTitle && <Heading variant="h2" key={topic.id} as="h2">{topic.title}</Heading>}
      {articles && articles.map(a => {
        return (
          <Box sx={{ mt: 2, mb: 3, border: '0px solid red' }}>
            <StyledLink key={a.id} to={`${getArticleUrl(a)}`}>{a.title}</StyledLink>
          </Box>
        )
      })}
    </Box>
  )
}

const StyledLink = styled(Link)`
  line-height: 1.8rem;
  padding: 0px 0;
  text-decoration: none;
  color: ${props => props.theme.colors.primary}
`

export default ArticlesList;
