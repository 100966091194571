/** @jsx jsx */
import { graphql, Link } from 'gatsby';
import { Heading, jsx } from 'theme-ui';
import { Box, Flex, Grid, Text } from '@theme-ui/components';
import Layout from '../components/organisms/Layout';
import Container from '../components/organisms/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import styled from '@emotion/styled';
import { getTopicUrl, isSiteArticle, isSiteTopic } from '../utils';
import ArticlesList from '../components/organisms/ArticlesList';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { SearchInputBox } from '../components/organisms/search';
import { SEO as Seo  } from "../components"

const StyledLink = styled(Link)`
  font-size: ${(props) => props.theme.fontSizes[2]};
  color: ${(props) => props.theme.colors.gray[6]};
  font-weight: normal;
  line-height: 1.8rem;
  text-decoration: none;
  margin: 20px;
  width: 250px;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.topicBg};
    box-shadow: 2px 2px 8px 0px ${(props) => props.theme.colors.topicShadow};
  }
`;

const SearchBox = ({filterApps}) => (
  <Container mt={2}>
    <SearchInputBox
      filterApps={filterApps}
      styles={{
        width: '100%',
        px: '8px',
        'input.inputBox': {
          width: '100%',
          border: '2px solid',
          borderColor: 'primary'
        },
      }}
    />
  </Container>
)

const TopicBlock = ({ app, tree: { topic } }) => {
  return (
    <StyledLink to={`${getTopicUrl(topic)}`}>
      <Flex
        dir="column"
        sx={{
          m: 0,
          p: 3,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'topicBorder',
          height: 120,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Flex dir="row" sx={{ mr: 3, p: 0, border: '0px solid red' }}>
          <Text>{topic.title}</Text>
        </Flex>
        <FontAwesomeIcon icon={faChevronRight} />
      </Flex>
    </StyledLink>
  );
};

const TopicList = ({ trees }) => {
  return (
    <Container>
      <Grid gap={15} columns={3}>
        {trees.map((tree) => (
          <TopicBlock key={tree.topic.id} app={tree.app} tree={tree} />
        ))}
      </Grid>
    </Container>
  );
};

const TopicCollection = ({ tree }) => {
  return (
    <Box sx={{ m: 2, p: 2, border: '1px solid #DEDEDE', borderRadius: 5 }}>
      <ArticlesList topic={tree.topic} articles={tree.articles} />
    </Box>
  );
};

const TopicCollections = ({ trees }) => {
  return (
    <Container sx={{ mt: 3 }}>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
        <Masonry>
          {trees.map((tree) => {
            return <TopicCollection key={tree.topic.id} tree={tree} />;
          })}
        </Masonry>
      </ResponsiveMasonry>
    </Container>
  );
};

const getTree = (topic, articles, siteMetadata) => {
  const arts = articles.nodes.filter(
    (a) => isSiteArticle(a, siteMetadata) && a.topic.id === topic.id
  );
  return { app: topic.app, topic, articles: arts };
};

const getTopicTree = (topic, topics, articles, siteMetadata) => {
  const subtopics = topics.nodes.filter(
    (t1) => isSiteTopic(t1, siteMetadata) && topic.id === t1?.parentTopic?.id
  );
  const subTrees = subtopics.map((st) => getTree(st, articles, siteMetadata));
  const tree = getTree(topic, articles, siteMetadata);
  tree['subTopics'] = subTrees;
  return tree;
};

const getTreeSet = (topics, articles, siteMetadata) => {
  return topics.nodes
    .filter((t) => isSiteTopic(t, siteMetadata) && t.parentTopic == null)
    .map((t) => getTopicTree(t, topics, articles, siteMetadata));
};

const AppTemplate = ({
  data: {
    app,
    topics,
    articles,
    site: { siteMetadata },
  },
  path,
  location,
}) => {
  const treeSet = getTreeSet(topics, articles, siteMetadata);

  const search = { showSearch: false };

  return (
    <Layout search={search}>
      <Seo datoSeo={app.seo} pathname={location.pathname} />
      <Box sx={{ backgroundColor: 'primary', py: [4, 4, 6], px: ['10%', '10%', '10%', '25%'] }}>
        <Heading as="h2" sx={{ textAlign: 'center', fontSize: 4, color: 'white', mb: 4, }}>How can we help you?</Heading>
        <SearchBox filterApps={app.slug}/>
      </Box>
      <Box>
        {app.presentation === 'Simple' && <TopicCollections trees={treeSet} />}
        {app.presentation !== 'Simple' && <TopicList trees={treeSet} />}
      </Box>
    </Layout>
  );
};

export default AppTemplate;

export const appTopicsQuery = graphql`
  query getAppTopics($recordId: String!) {
    site {
      siteMetadata {
        apps
        excludedTopics
        excludedArticles
      }
    }
    app: datoCmsApp(id: { eq: $recordId }) {
      id
      title
      seo{
        description
        title
      }      
      slug
      presentation
    }

    topics: allDatoCmsTopic(
      filter: { app: { id: { eq: $recordId } } }
      sort: { fields: sortOrder }
    ) {
      nodes {
        id
        slug
        title
        app {
          id
          slug
          title
        }
        parentTopic {
          id
          title
          slug
        }
      }
    }

    articles: allDatoCmsArticle(
      filter: { topic: { app: { id: { eq: $recordId } } } }
      sort: {fields: displayOrder, order: ASC}
    ) {
      nodes {
        id
        slug
        title
        topic {
          id
          slug
          title
          app {
            id
            slug
            title
          }
        }
      }
    }
  }
`;
